<template>
  <div id="app">
    <!-- <keep-alive include="Home,Point,User"> -->
      <router-view/>
    <!-- </keep-alive> -->
  </div>
</template>


<script>
export default {
  name: 'App'
}
</script>