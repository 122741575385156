import Vue from 'vue';
import Router from 'vue-router';
const Home = () => import(/* webpackChunkName: "Home" */ '@/views/Home/Home');
const WebPanoView = () => import(/* webpackChunkName: "Home" */ '@/views/PanoPlayer/PanoPlayer');

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        title: '全景视频',
      }
    },
    {
      path: '/panoplayer',
      name: 'WebPanoView',
      component: WebPanoView,
      meta: {
        title: '全景视频',
      }
    }
  ]
});
